<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card flat tile
                :width="$vuetify.breakpoint.width"
                :min-height="$vuetify.breakpoint.height - 192"
                class="presize-padding all"
                color="#F9F9FA">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="pa-0 ma-0 mt-n16">
              <app-back-button-component/>
            </v-col>
          </v-row>
          <v-card-title class="page-title px-0 d-flex justify-center">
            <span class="page-title-number">{{ number }}</span>
            <span class="ml-6">{{ title }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <template v-if="cards.length > 0">
              <div class="px-0 ma-0 page-card-container">
                <div v-for="card of cards" :key="card.id" class="page-card"
                     @click="goTo(card.link, {title: card.text})">
                  <span class="page-card-text">{{ card.text }}</span>
                  <v-btn width="29" height="16" class="page-card-arrow" text :ripple="false">
                    <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5423 0.111004C19.8981 0.406991 19.5957 1.2066 19.8888 1.83884C19.965 2.00322 20.7694 2.86004 22.2365 4.33947C23.4639 5.57725 24.4681 6.61121 24.4681 6.63722C24.4681 6.66316 19.1734 6.68442 12.702 6.68442H0.935987L0.663505 6.82991C0.24446 7.05369 0.028601 7.41038 0.00270038 7.92195C-0.0228911 8.42739 0.132574 8.78815 0.505011 9.08795L0.742567 9.27915L12.6053 9.29528C19.1299 9.3041 24.4681 9.33242 24.4681 9.35812C24.4681 9.38382 23.4526 10.4316 22.2113 11.6866C19.7752 14.1496 19.7225 14.2166 19.7849 14.7768C19.8265 15.1501 20.0919 15.5906 20.399 15.7961C20.7443 16.0273 21.3151 16.0681 21.6606 15.8862C21.9679 15.7245 28.7425 8.86106 28.8919 8.56013C29.044 8.25395 29.0343 7.71944 28.8709 7.40344C28.6819 7.03768 21.9084 0.214481 21.6112 0.0903714C21.3046 -0.037677 20.8466 -0.0287985 20.5423 0.111004Z" fill="#D8BC76"/>
                    </svg>
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-else>
              <span class="page-title-nothing-to-show d-block my-16 text-center">Nichts zu sehen</span>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-prototype-builtins */
export default {
  name: "Login",
  mounted() {
    this.$route.params.hasOwnProperty('items') &&
    this.$route.params.hasOwnProperty('title') &&
    this.$route.params.hasOwnProperty('number') ?
        this.$store.dispatch('setParams', this.$route.params) :
        null
  },
  methods: {
    goTo(link, params = null) {
      console.log(link)
      switch (link.includes('http') || link.includes('https')) {
        case true:
          window.open(link, '_blank')
          break
        case false:
          this.$router.push({name: link, params: params})
          break
      }
    }
  },
  computed: {
    cards () {
      return this.$route.params.items ? this.$route.params.items : this.$store.getters.GET_PARAMS.items
    },
    title () {
      return this.$route.params.title ? this.$route.params.title : this.$store.getters.GET_PARAMS.title
    },
    number () {
      return this.$route.params.number ? this.$route.params.number : this.$store.getters.GET_PARAMS.number
    }
  }
}
</script>

<style scoped lang="scss">
.page-title-nothing-to-show {
  color: #18283B;
  font-size: 100px;
  opacity: .5;
}
.page-title {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 32px !important;
  color: #18283B;
  transition: .45s ease-in-out;
  & .page-title-number {
    color: #D8BC76;
    font-size: 100px;
    opacity: .5;
  }
}
.page-card-container {
  display: grid;
  grid-template-rows: repeat(3, 178px);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  padding-top: 60px;
  padding-bottom: 0;
  & .page-card {
    position: relative;
    border: 2px solid #18283B;
    color: #18283B;
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .45s ease-in-out;
    & .page-card-text {
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 160%;
      color: #18283B;
      transition: color .45s ease-in-out;
      white-space: pre-line;
      text-align: center;
    }
    & .page-card-arrow {
      opacity: 0;
      position: absolute;
      left: 86.73%;
      top: 83.15%;
      transition: opacity .45s ease-in-out;
    }
    &:hover {
      background-color: #18283B;
      cursor: pointer;

      & .page-card-arrow {
        opacity: 1;
      }
      & .page-card-text {
        color: #FFF;
      }
    }
  }
}
</style>